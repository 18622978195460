import React, { useState } from 'react';
import FaqQuestion from 'components/FaqQuestion';
import Search from 'components/Search';
import { Stack } from '@tymate/margaret';
import Fuse from 'fuse.js';

const FaqSearchAndResults = ({ content }) => {
  const [search, setSearch] = useState('');

  const fuseResults = new Fuse(content || [], {
    keys: ['question', 'answer'],
    ignoreLocation: true,
    useExtendedSearch: true,
    ignoreFieldNorm: true,
    includeScore: true,
    threshold: 0.3,
  }).search(search);

  const items = Boolean(search)
    ? fuseResults.map(({ item }) => item)
    : content ?? [];

  return (
    <div style={{ minHeight: '10rem' }}>
      <Stack paddingVertical={2} size="full" alignX="center">
        <Search value={search} onChange={setSearch} />
      </Stack>

      <Stack direction="column" gutterSize={1} marginTop={2}>
        {items.map(({ question, id, answerNode }) => (
          <FaqQuestion title={question} answerNode={answerNode} key={id} />
        ))}
      </Stack>
    </div>
  );
};

export default FaqSearchAndResults;
