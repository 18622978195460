import React, { useState } from 'react';
import styled from 'styled-components';
import { Stack, ButtonReset } from '@tymate/margaret';
import { FaCaretRight } from 'react-icons/fa';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing()};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: left;
`;

const Question = styled(Stack).attrs({
  as: ButtonReset,
  size: 'full',
  alignX: 'space-between',
  alignY: 'center',
})`
  font-weight: 700;
  text-align: left;
`;

const Answer = styled.div`
  color: ${({ theme }) => theme.textLight};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled(Stack)`
  transition: transform 150ms ease;
  color: ${({ theme }) => theme.red};

  ${({ isExpanded }) =>
    isExpanded &&
    `
      transform: rotate(90deg);
    `};
`;

const FaqQuestion = ({ title, answerNode }) => {
  const [isExpanded, setIsExpanded] = useState();

  return (
    <Wrapper>
      <Question onClick={() => setIsExpanded(!isExpanded)}>
        <span>{title}</span>
        <Icon isExpanded={isExpanded}>
          <FaCaretRight />
        </Icon>
      </Question>

      {isExpanded && (
        <Answer
          dangerouslySetInnerHTML={{
            __html: answerNode?.childMarkdownRemark?.html,
          }}
        />
      )}
    </Wrapper>
  );
};

export default FaqQuestion;
