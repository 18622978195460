import React, { useRef } from 'react';
import { ButtonReset, Stack } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { MdSearch, MdCancel } from 'react-icons/md';

const Icon = styled(Stack)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: var(--show);

  ${({ variant }) =>
    variant === 'left' &&
    css`
      left: ${({ theme }) => theme.spacing()};
    `}

  ${({ variant }) =>
    variant === 'right' &&
    css`
      right: ${({ theme }) => theme.spacing()};
    `}
`;

const Wrapper = styled(Stack).attrs({ size: 'full', alignX: 'stretch' })`
  position: relative;
  width: 25em;
  max-width: 100%;
`;

const SearchInput = styled.input`
  border: 1px solid ${({ theme }) => theme.separator};
  height: 48px;
  outline: none;
  border-radius: 999em;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing(2.75)};
  display: flex;
  align-items: center;
  line-height: 24px;
`;

const Search = ({ value, onChange, placeholder }) => {
  const inputRef = useRef();

  const handleClear = () => {
    onChange('');

    if (Boolean(inputRef.current)) {
      inputRef.current.focus();
    }
  };

  return (
    <Wrapper>
      <Icon variant="left" style={{ '--show': 'block' }}>
        <MdSearch size={24} />
      </Icon>
      <SearchInput
        ref={inputRef}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <Icon
        variant="right"
        style={{ '--show': Boolean(value) ? 'block' : 'none' }}
      >
        <ButtonReset type="button" onClick={handleClear}>
          <MdCancel size={16} />
        </ButtonReset>
      </Icon>
    </Wrapper>
  );
};

export default Search;
